@font-face {
  font-family: 'Amsterdam';
  src: url('./../../../../assets/fonts/Amsterdam.ttf') format('truetype');
}
.certifications.container {
  background-color: rgba(246, 246, 246, 1);
  position: absolute;
  text-align: center;
  width: 1056px;
  height: 816px;
  page-break-inside: avoid;
  overflow: hidden;
}

.certifications .logo {
  position: absolute;
  background-image: url('./../../../../assets/static/logo.svg');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 51px;
  height: 57px;
  left: 70px;
  top: 52px;
}

.certifications .certificate_date {
  position: absolute;
  height: 44px;
  width: 215px;
  font-family: 'Raleway';
  font-size: 13px;
  font-weight: 700;
  left: 786px;
  top: 52px;
  border-radius: 10px;
  align-content: center;
}

.certifications.graphic {
  position: absolute;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 330px;
  height: 270px;
  left: 670px;
  top: 135px;
}

.certifications .recongnize {
  position: absolute;
  height: 200px;
  width: 500px;
  left: 70px;
  top: 140px;
  text-align: left;
}

.certifications .recongnize p {
  position: absolute;
  font-weight: 700;
  font-size: 87px;
  line-height: 0.9;
}

.certifications .studentName {
  position: absolute;
  font-style: italic;
  font-size: 32px;
  text-align: left;
  left: 70px;
  top: 470px;
}

.certifications .above_line {
  position: absolute;
  left: 70px;
  top: 520px;
  border: 1px black solid;
  height: 1px;
  width: 370px;
}

.certifications .merit {
  position: absolute;
  font-family: 'Raleway';
  font-size: 21px;
  left: 70px;
  top: 540px;
  text-align: left;
  width: 500px;
}

.certifications .course_name {
  font-weight: 700;
  display: inline;
}

.certifications .signature {
  position: absolute;
  font-family: 'Amsterdam' !important;
  font-style: italic;
  font-weight: 500;
  font-size: 25px;
  left: 70px;
  top: 710px;
  text-align: left;
  width: 670px;
}

.certifications .below_line {
  position: absolute;
  left: 70px;
  top: 756px;
  border: 1px black solid;
  height: 1px;
  width: 540px;
}

.certifications .verse_container {
  position: absolute;
  top: 470px;
  left: 700px;
  width: 290px;
  height: 300px;
  font-size: 14px;
  text-align: left;
}

.certifications .verse_container p {
  font-size: 18px;
}

.certifications .left_text {
  text-align: right;
}
